import { Injectable } from '@angular/core';
import { TokenType } from '../constants/token-type.constant';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  get accessToken(): string {
    return localStorage.getItem(TokenType.ACCESS_TOKEN) ?? '';
  }

  set accessToken(token: string) {
    localStorage.setItem(TokenType.ACCESS_TOKEN, token);
  }
}
